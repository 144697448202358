import { Directive, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConfig } from '../services/global-config-service';
import { CheckHasSlug } from './base/check-has-slug-base';

@Directive({
  selector: '[hideBySlug]',
})
export class HideElementBySlug extends CheckHasSlug {
  constructor(
    override el: ElementRef,
    override router: Router,
    override route: ActivatedRoute,
    override globalConfig: GlobalConfig
  ) {
    super(el, router, route, globalConfig);
  }
}
