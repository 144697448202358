<header
  class="w-full md:sticky top-0 bg-white z-[1000] border-b border-b-slate-100">
  <div id="header-container" class="flex relative items-stretch">
    <div class="flex items-center space-x-2 mr-auto p-4">
      <button
        type="button"
        aria-label="Open navigation menu"
        hideBySlug
        class="lg:hidden"
        (click)="handleToggle()">
        <span class="sr-only">Open Navigation</span>
        <svg
          width="24"
          height="24"
          aria-hidden="true"
          role="img"
          focusable="false"
          viewBox="0 0 24 24"
          fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.5 6H19.5V7.5H4.5V6ZM4.5 12H19.5V13.5H4.5V12ZM19.5 18H4.5V19.5H19.5V18Z"
            fill="currentColor"></path>
        </svg>
      </button>
      <a aria-label="youmed logo" hideBySlug [href]="environment.returnUrlHome">
        <img
          width="118"
          height="25"
          src="./assets/img/booking/logo.svg"
          alt="YouMed" />
      </a>
    </div>
    <nav
      hideBySlug
      [ngClass]="{ hidden: activeToggle }"
      class="bg-white absolute z-[100] top-0 right-0 left-0 transition transform origin-top-right h-screen lg:flex lg:h-auto overflow-scroll lg:overflow-visible lg:relative">
      <div class="lg:hidden sticky top-0">
        <button
          (click)="handleToggle()"
          type="button"
          aria-label="Toggle navigation"
          class="bg-slate-100 rounded-full p-1 m-3">
          <span class="sr-only">Close Navigation</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.5459 9.54593L15.9099 15.9099"
              stroke="#1F2937"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M9.54595 15.9099L15.9099 9.54593"
              stroke="#1F2937"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
        </button>
      </div>
      <div class="lg:flex items-stretch">
        <ul class="px-6 lg:px-0 font-semibold lg:flex text-sm items-center">
          <li class="group relative">
            <a
              data-toggle="dropdown"
              href="#"
              class="hidden lg:inline-flex flex-row whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg lg:items-center lg:py-2 lg:mx-2"
              >{{ 'header.booking' | translate }}
              <div class="hidden lg:inline-block dropdown-toggle"></div>
            </a>
            <ul
              class="group-hover:block overflow-hidden lg:hidden lg:absolute lg:top-75 lg:bg-white lg:rounded-xl lg:shadow-yu">
              <li class="lg:mt-4">
                <a
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:p-4 lg:mx-4"
                  routerLink="/bac-si"
                  >{{ 'header.booking.doctor' | translate }}
                  <p class="hidden md:block text-sm font-normal text-gray-700">
                    {{ 'header.booking.doctor.desc' | translate }}
                  </p>
                </a>
              </li>
              <li class="lg:mt-4">
                <a
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:p-4 lg:mx-4"
                  routerLink="/benh-vien"
                  >{{ 'header.booking.hospital' | translate }}
                  <p class="hidden md:block text-sm font-normal text-gray-700">
                    {{ 'header.booking.hospital.desc' | translate }}
                  </p>
                </a>
              </li>
              <li class="lg:mt-4">
                <a
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:p-4 lg:mx-4"
                  routerLink="/phong-kham"
                  >{{ 'header.booking.clinic' | translate }}
                  <p class="hidden md:block text-sm font-normal text-gray-700">
                    {{ 'header.booking.clinic.desc' | translate }}
                  </p>
                </a>
              </li>
              <li class="lg:mt-4">
                <a
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:p-4 lg:mx-4"
                  routerLink="/tiem-chung"
                  >{{ 'header.booking.subclinical' | translate }}
                  <p class="hidden md:block text-sm font-normal text-gray-700">
                    {{ 'header.booking.subclinical.desc' | translate }}
                  </p>
                </a>
              </li>

              <li class="lg:mt-4">
                <a
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:p-4 lg:mx-4"
                  routerLink="/xet-nghiem"
                  >Đặt lịch xét nghiệm
                  <p class="hidden md:block text-sm font-normal text-gray-700">
                    Trung tâm xét nghiệm uy tín
                  </p>
                </a>
              </li>

              <li class="lg:mt-4">
                <a
                  href="tel:19002805"
                  class="hidden font-medium p-6 rounded-none lg:flex whitespace-nowrap bg-slate-100"
                  >Bạn cần hỗ trợ? Gọi cho chúng tôi 1900-2805</a
                >
              </li>
            </ul>
          </li>
          <li class="lg:flex items-center">
            <a
              class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:py-2 lg:mx-2"
              [href]="NAVIGATION.YOUMED.online_counseling"
              target="_blank"
              >{{ 'header.online-counseling' | translate }}</a
            >
          </li>
          <li class="lg:flex items-center">
            <a
              class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:py-2 lg:mx-2"
              [href]="NAVIGATION.YOUMED.store"
              target="_blank"
              >Store</a
            >
          </li>
          <li class="lg:flex items-center">
            <a
              class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:py-2 lg:mx-2"
              [href]="NAVIGATION.SOCIAL.youmed_news"
              target="_blank"
              >{{ 'home.news.h2' | translate }}</a
            >
          </li>
        </ul>
      </div>
      <div class="lg:flex items-center">
        <ul class="px-6 lg:px-0 lg:flex text-sm font-normal">
          <li class="group">
            <a
              data-toggle="dropdown"
              href="#"
              class="hidden px-4 lg:flex items-center whitespace-nowrap hover:bg-slate-100 rounded-lg lg:py-2 lg:mx-2"
              >{{ 'header.medical-staff' | translate }}
              <div class="hidden lg:inline-block dropdown-toggle"></div>
            </a>
            <ul
              class="lg:p-4 group-hover:block overflow-hidden lg:hidden lg:absolute lg:top-75 lg:bg-white lg:rounded-xl lg:shadow-yu">
              <li>
                <a
                  [href]="NAVIGATION.YOUMED.hcp"
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:py-2">
                  {{ 'header.medical-staff.hcp' | translate }}
                </a>
              </li>
              <li>
                <a
                  [href]="NAVIGATION.YOUMED.clinic"
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:py-2">
                  YouMed Clinic
                </a>
              </li>
              <li>
                <a
                  [href]="NAVIGATION.YOUMED.y360"
                  class="flex whitespace-nowrap hover:bg-slate-100 p-4 rounded-lg flex-col lg:items-start lg:py-2">
                  Y360
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <a
        href="https://youmed.page.link/apps"
        target="_blank"
        class="flex lg:hidden items-center px-6 py-4 space-x-2 bg-slate-100 mt-4 mb-24">
        <svg
          width="32"
          height="53"
          viewBox="0 0 32 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 9.6C0.5 7.91159 0.500389 6.67536 0.580085 5.69993C0.659303 4.73034 0.814384 4.06255 1.09946 3.50305C1.62677 2.46816 2.46816 1.62677 3.50305 1.09946C4.06255 0.814384 4.73034 0.659303 5.69993 0.580085C6.67536 0.500389 7.91159 0.5 9.6 0.5H22.4C24.0884 0.5 25.3246 0.500389 26.3001 0.580085C27.2697 0.659303 27.9374 0.814384 28.4969 1.09946C29.5318 1.62677 30.3732 2.46816 30.9005 3.50305C31.1856 4.06255 31.3407 4.73034 31.4199 5.69993C31.4996 6.67536 31.5 7.91159 31.5 9.6V43.4C31.5 45.0884 31.4996 46.3246 31.4199 47.3001C31.3407 48.2697 31.1856 48.9374 30.9005 49.4969C30.3732 50.5318 29.5318 51.3732 28.4969 51.9005C27.9374 52.1856 27.2697 52.3407 26.3001 52.4199C25.3246 52.4996 24.0884 52.5 22.4 52.5H9.6C7.91159 52.5 6.67536 52.4996 5.69993 52.4199C4.73034 52.3407 4.06255 52.1856 3.50305 51.9005C2.46816 51.3732 1.62677 50.5318 1.09946 49.4969C0.814384 48.9374 0.659303 48.2697 0.580085 47.3001C0.500389 46.3246 0.5 45.0884 0.5 43.4V9.6Z"
            fill="#F9FAFB"
            stroke="#D1D5DB"></path>
          <rect
            x="11"
            y="49.5442"
            width="10"
            height="1"
            rx="0.5"
            fill="#D1D5DB"></rect>
          <path
            d="M8 0.544189H24C24 1.64876 23.1046 2.54419 22 2.54419H10C8.89543 2.54419 8 1.64876 8 0.544189Z"
            fill="#D1D5DB"></path>
          <path
            d="M21.0583 28.5692L16 33.6275L10.9417 28.5692"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"></path>
          <path
            d="M16 19.4609V33.4859"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"></path>
        </svg>
        <div class="block">
          <p class="text-sm font-semibold text-gray-800">
            {{ 'home.download.t' | translate }}
          </p>
          <p class="text-xs font-medium text-gray-500">
            {{ 'home.download.des' | translate }}
          </p>
        </div>
      </a>
    </nav>
    <div class="inline-flex space-x-3 items-center">
      <div *ngIf="!currentUser; else user" class="px-4">
        <a
          routerLink="/login"
          (click)="handleRoute()"
          class="btn-outline my-2 py-2 whitespace-nowrap"
          >{{ 'auth.signIn.t' | translate }}</a
        >
      </div>
      <ng-template #user>
        <div class="group relative">
          <div
            class="px-4 hover:text-primary flex items-center whitespace-nowrap rounded-xl font-semibold cursor-pointer truncate text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 512 512">
              <path
                fill="currentColor"
                d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z" />
              <path
                fill="currentColor"
                d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z" />
            </svg>
            <span class="hidden md:block">{{ currentUserName }}</span>
            <div class="dropdown-toggle"></div>
          </div>
          <ul
            class="p-4 z-50 text-sm group-hover:block overflow-hidden right-5 hidden absolute top-75 bg-white rounded-lg shadow-yu">
            <li>
              <a
                routerLink="/lich-kham"
                class="flex whitespace-nowrap hover:bg-slate-100 px-4 py-2 rounded-lg flex-col items-start">
                {{ 'auth.appointment-schedule.t' | translate }}
              </a>
            </li>
            <li>
              <a
                routerLink="/thanh-toan"
                class="flex whitespace-nowrap hover:bg-slate-100 px-4 py-2 rounded-lg flex-col items-start">
                {{ 'auth.payment-history.t' | translate }}
              </a>
            </li>
            <li>
              <a
                routerLink="/ho-so"
                class="flex whitespace-nowrap hover:bg-slate-100 px-4 py-2 rounded-lg flex-col items-start">
                {{ 'auth.profile.t' | translate }}
              </a>
            </li>
            <li>
              <a
                routerLink="/logout"
                class="flex whitespace-nowrap hover:bg-slate-100 px-4 py-2 rounded-lg flex-col items-start hover:text-red-500">
                {{ 'auth.logout.t' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>
</header>
