import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  LazyLoadImageModule,
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from 'ng-lazyload-image';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { SharedGeneralDirectiveModule } from '../shared/directives/shared-general-diractive.module';
import { LayoutBasicComponent } from './basic/basic.component';
import { LayoutBlankComponent } from './blank/blank.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { SearchLayoutComponent } from './search-layout/search-layout.component';

const COMPONENTS = [
  LayoutBasicComponent,
  LayoutBlankComponent,
  NavComponent,
  FooterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LazyLoadImageModule,
    NzButtonModule,
    NzCollapseModule,
    TranslateModule,
    SharedGeneralDirectiveModule,
  ],
  declarations: [...COMPONENTS, SearchLayoutComponent],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
})
export class LayoutModule {}
