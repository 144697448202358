import { Injectable } from '@angular/core';
import { RegisterEnum } from 'src/app/core/enums/register-tab.enum';

@Injectable()
export class TransferRegisterService {
  phoneNumber = '';
  sessionInfoByOtpSend = '';
  sessionInfoByCheckAndSend = '';
  currentTabIndex: RegisterEnum = RegisterEnum.VERIFY_OPT;
  isCreatePassComplete = false;
  smsId = '';
  constructor() { }
}
