import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Constant } from '../constant/constant';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (isPlatformBrowser(this.platformId)) {
      var isLogged = this.authService.isLoggedIn();
      var result = true;
      if (!isLogged) {
        result = false;
        // // not logged in so redirect to login page with the return url and return false
        this.router.navigate([Constant.pages.login.alias], {
          queryParams: { returnUrl: decodeURIComponent(state.url) },
        });
      }
      return result;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platformId)) {
      var isLogged = this.authService.isLoggedIn();
      var result = true;
      if (!isLogged) {
        result = false;
        this.router.navigate([Constant.pages.login.alias]);
      }
      return result;
    }
  }
}
