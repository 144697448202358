export const NAVIGATION = {
  BASE: {
    root: '/',
    login: 'login',
    logout: 'logout',
    register: 'register',
    search: 'search',
    book: 'dat-kham',
    appointment: 'lich-kham',
    payment: 'thanh-toan',
    profile: 'ho-so',
    account: 'tai-khoan',
    booking_hospital: 'benh-vien',
    booking_bv: 'bv',
    booking_doctor: 'bac-si',
    booking_clinic: 'phong-kham',
    booking_lab: 'tiem-chung',
    booking_test: 'xet-nghiem',
  },
  HOME: {},
  CLINIC: {},
  DOCTOR: {},
  SOCIAL: {
    youmed_news: 'https://youmed.vn/tin-tuc',
    zalo: 'https://zalo.me/2433559446561086041',
    facebook: 'https://www.facebook.com/YouMedVN',
    linkin: 'https://www.linkedin.com/in/youmedvn/',
    youtube: 'https://www.youtube.com/channel/UCR4EoGCCtS7JA05PtOLSChg',
  },
  CERTIFICATE: {
    hipaa: '',
    pdpa: '',
    hon: 'https://www.healthonnet.org/HONcode/Conduct.html?HONConduct10870863',
    bo_cong_thuong:
      'http://online.gov.vn/HomePage/WebsiteDisplay.aspx?DocId=53817',
    dmca: 'https://www.dmca.com/Protection/Status.aspx?ID=a74b3497-ddcd-4860-89c3-fce83c39f12a&refurl=',
  },
  DOWNLOAD: {
    ios: 'https://itunes.apple.com/gb/app/youmed/id1466077723?mt=8',
    android: 'https://play.google.com/store/apps/details?id=com.youmed.info',
  },
  YOUMED: {
    about_us: 'https://youmed.vn/gioi-thieu-ve-youmed',
    co_founder: 'https://youmed.vn/tin-tuc/ban-dieu-hanh-youmed',
    adviser: 'https://youmed.vn/tin-tuc/hoi-dong-tham-van-y-khoa',
    editor: 'https://youmed.vn/tin-tuc/doi-ngu-bien-tap-vien',
    recruit: 'https://youmed.vn/nhan-su-tuyen-dung',
    contact: 'https://youmed.vn/lien-he',
    regulation: 'https://youmed.vn/quy-che-hoat-dong',
    qa: 'https://youmed.vn/faqs/faq',
    rules: 'https://youmed.vn/dieu-khoan-su-dung',
    security: 'https://youmed.vn/chinh-sach-bao-mat',
    complain:
      'https://youmed.vn/quy-trinh-tiep-nhan-va-giai-quyet-khieu-nai-cua-khach-hang',
    hcp: 'https://youmed.vn/hcp',
    clinic: 'https://youmed.vn/youmed-clinic',
    store: 'https://youmedstore.vn',
    y360: 'https://y360.vn',
    online_counseling: 'https://youmed.vn/tu-van-truc-tuyen',
  },
};
