import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LANGUAGE, LAN_DROPDOWN } from 'src/app/core/constant/app.constants';
import { NAVIGATION } from 'src/app/core/constant/route.constants';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { UserService } from 'src/app/core/services/user.service';
import { ArrayUtil } from 'src/app/core/utils/array';
import { DestroyableComponent } from 'src/app/shared/abstracts/destroyable.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less'],
})
export class NavComponent extends DestroyableComponent implements OnInit {
  NAVIGATION = NAVIGATION;
  LANGUAGE = LANGUAGE;
  currentLang = '';
  languages = LAN_DROPDOWN;
  environment = environment;
  activeToggle: boolean = true;

  get currentUser() {
    return this.userService.getCurrentUser();
  }

  get currentUserName() {
    return this.sliceName(
      this.currentUser?.name ?? this.currentUser?.userName,
      15
    );
  }

  hasRouteBV = false;
  returnUrlFBtnLogin = '';

  constructor(
    private router: Router,
    private langService: LanguageService,
    private auth: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID)
    private platformId: any
  ) {
    super();
    this.currentLang = this.langService.lang;

    this.subscribeRouter();
  }

  subscribeRouter() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        let snapshotUrl = this.route.snapshot.queryParams['returnUrl'];
        if (snapshotUrl?.leng > 15) {
          if (snapshotUrl.indexOf('login?returnUrl=') == 0) {
            return;
          }
        }

        const url = event.urlAfterRedirects;
        const arr = url.split('/');
        if (!ArrayUtil.isNullOrZero(arr)) {
          if (
            !ArrayUtil.isNullOrZero(arr) &&
            arr[1].indexOf('login?returnUrl') === 0
          ) {
            this.returnUrlFBtnLogin = snapshotUrl;
          }
        }

        const state = this.router.getCurrentNavigation()?.extras?.state;
        if (!ArrayUtil.isNullOrZero(arr) && arr[1]?.indexOf('bv') !== -1) {
          this.hasRouteBV = true;
        }

        if (state && state?.returnUrl?.indexOf('/bv') === 0) {
          this.hasRouteBV = true;
        }

        if (!ArrayUtil.isNullOrZero(arr) && arr?.length === 3) {
          let prefix = this.hasRouteBV ? 'bv' : arr[1];
          this.returnUrlFBtnLogin = prefix + '/' + arr[2] + '/dat-kham';
        } else if (!ArrayUtil.isNullOrZero(arr) && arr?.length == 2) {
          this.returnUrlFBtnLogin = arr[1];
        }
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const bodyElement = document.querySelector('body') as any;
      bodyElement?.classList?.remove('overflow-hidden');
    }
  }

  handleToggle(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.activeToggle = !this.activeToggle;
      const bodyElement = document.querySelector('body') as any;
      bodyElement?.classList?.toggle('overflow-hidden');
    }
  }

  navigateTo(url: string): void {
    if (url) this.router.navigate([`${url}`]);
  }

  sliceName(value: any, length: any): any {
    try {
      if (value?.length <= length) {
        return value;
      }
      const str = value.slice(0, length) + '...';
      return str;
    } catch (error) {
      return value;
    }
  }

  logout() {
    this.auth.logout(true);
  }

  switchLang(lang: string) {
    this.currentLang = lang;
    this.langService.saveLang(this.currentLang);
    this.langService.switchLanguage(this.currentLang);
  }

  handleRoute() {
    let url = this.router.url;
    const arr = url.split('/');
    if (!ArrayUtil.isNullOrZero(arr) && arr[1]?.indexOf('bv') !== -1) {
      this.hasRouteBV = true;
    }

    if (url !== '/') {
      this.router.navigate(['/login'], {
        state: { returnUrl: url },
      });
    }
  }
}
