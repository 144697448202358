import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LANGS,
  LANGUAGE,
  LAN_DROPDOWN,
} from 'src/app/core/constant/app.constants';
import { NAVIGATION } from 'src/app/core/constant/route.constants';
import { LanguageService } from 'src/app/core/services/lang.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
  NAVIGATION = NAVIGATION;
  LANGUAGE = LANGUAGE;
  currentLAN = LANGUAGE.VI;
  languages = LAN_DROPDOWN;
  langs = LANGS;
  currentLang = '';
  currentYear: number = new Date().getFullYear();
  href: string = '';

  get langSrv(): LanguageService {
    return this.langService;
  }

  constructor(private router: Router, private langService: LanguageService) {
    this.currentLang = this.langService.lang;
  }

  ngOnInit(): void {
    this.href = this.router.url;
  }

  navigateTo(url: string): void {
    if (url) this.router.navigate([`${url}`]);
  }

  switchLang(lang: string) {
    this.currentLang = lang;
    this.langService.saveLang(this.currentLang);
    this.langService.switchLanguage(this.currentLang);
  }
}
