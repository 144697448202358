import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Constant } from '../constant/constant';
import { HttpCacheService } from './cache/http-cache.service';

@Injectable({
  providedIn: 'root',
})
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private cacheService: HttpCacheService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  private checkStatus(ev: HttpResponseBase): void {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }
  }

  private goTo(url: string): void {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private handleData(
    ev: HttpResponseBase,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    this.checkStatus(ev);
    switch (ev.status) {
      case 0:
        this.onlineCheck();
        break;
      case 200:
        break;
      case 401:
        // NOTE : Có ảnh hưởng đến thẻ canonical
        this.toLogin();
        break;
      case 403:
      // case 404:
      case 500:
        this.goToErrorPage(`/error/500`, true);
        break;
      case 502:
        this.goToErrorPage(`/error/503`, true);
        break;
      case 503:
        this.goToErrorPage(`/error/503`, true);
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn(
            'Unknown errors, mostly caused by the backend does not support cross-domain CORS or invalid configuration, Status: ' +
              ev.status,
            ev
          );
        }
        break;
    }
    if (ev instanceof HttpErrorResponse) {
      return throwError(ev);
    } else {
      return of(ev);
    }
  }

  toLogin(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate([Constant.pages.login.alias]);
    }
  }

  goToErrorPage(url: string, skipLocationChange = false): void {
    if (skipLocationChange) this.router.navigate([url], { skipLocationChange });
    else this.router.navigate([url]);
  }

  onlineCheck(): void {
    let isOnline = true;
    let xhr = new XMLHttpRequest();
    //TODO : Change order URL
    xhr.open('GET', 'https://jsonplaceholder.typicode.com/posts', true);
    xhr.onload = () => {
      if (xhr.status == 200 && xhr.status < 300) {
        isOnline = true;
      } else {
        isOnline = false;
        this.goToErrorPage('/no-internet', true);
      }
    };
    xhr.onerror = () => {
      isOnline = false;
      this.goToErrorPage('/no-internet', true);
    };
    xhr.send(); //sending get request to the passed url
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.getToken();
    let headers = req.headers;
    if (!req.url.includes('hub.youmed.vn')) {
      headers = headers
        .set('X-YM-APP', 'WEB')
        .set('X-YM-APP-PLATFORM', 'WEB')
        .set('X-YM-APP-VERSION', '1.0');
    }
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    const modReq = req.clone({ headers });
    return this.handleCache(req, modReq, next);
  }

  private getToken(): string | null {
    const ob = localStorage.getItem(Constant.ls.auth);
    if (ob) {
      const user = JSON.parse(ob);
      return user.access_token;
    }
    return null;
  }

  private handleCache(
    req: HttpRequest<any>,
    modReq: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldUseCache(req)) {
      const cachedResponse = this.cacheService.get(req.url);
      if (cachedResponse) {
        return of(cachedResponse);
      }
    }

    return next.handle(modReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse && this.shouldCache(req)) {
          this.cacheService.put(req.url, event);
        }
      }),
      catchError((err: HttpErrorResponse) =>
        this.handleError(err, modReq, next)
      )
    );
  }

  private shouldUseCache(req: HttpRequest<any>): boolean {
    const urlWithParams = req.urlWithParams;
    return urlWithParams.includes('?cache=true');
  }

  private shouldCache(req: HttpRequest<any>): boolean {
    // Điều kiện để cache, ví dụ:
    return req.method === 'GET';
  }

  private handleError(
    err: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Xử lý lỗi tùy ý
    return throwError(err);
  }
}
