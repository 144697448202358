import { Component, OnInit } from '@angular/core';
import { LanguageService } from './core/services/lang.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  title = 'booking';
  constructor(private langService: LanguageService) {
    this.langService.addLangs();
    this.langService.setDefaultLang();
  }
  ngOnInit(): void {}
}
