import { Directive, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ArrayUtil } from 'src/app/core/utils/array';
import { GlobalConfig } from '../../services/global-config-service';

@Directive()
export class CheckHasSlug {
  @Input() slug = 'bv';
  slugContain = false;
  hasRouteBV = false;
  returnUrlFBtnLogin = '';
  constructor(
    protected el: ElementRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected globalConfig: GlobalConfig
  ) {
    this.subscribeRouter();
  }

  subscribeRouter() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        let snapshotUrl = this.route.snapshot.queryParams['returnUrl'];
        if (snapshotUrl?.length > 15) {
          if (snapshotUrl.indexOf('login?returnUrl=') == 0) {
            return;
          }
        }

        const url = event.urlAfterRedirects;
        const arr = url.split('/');
        if (!ArrayUtil.isNullOrZero(arr)) {
          if (
            !ArrayUtil.isNullOrZero(arr) &&
            arr[1].indexOf('login?returnUrl') === 0
          ) {
            this.returnUrlFBtnLogin = snapshotUrl;
          }
        }

        const state = this.router.getCurrentNavigation()?.extras?.state;
        if (!ArrayUtil.isNullOrZero(arr) && arr[1]?.indexOf(this.slug) !== -1) {
          this.slugContain = true;
        }

        if (state && state?.returnUrl?.indexOf(`/${this.slug}`) === 0) {
          this.slugContain = true;
        }

        if (!ArrayUtil.isNullOrZero(arr) && arr?.length === 3) {
          let prefix = this.slugContain ? this.slug : arr[1];
          this.returnUrlFBtnLogin = prefix + '/' + arr[2] + '/dat-kham';
        } else if (!ArrayUtil.isNullOrZero(arr) && arr?.length == 2) {
          this.returnUrlFBtnLogin = arr[1];
        }
        if (this.slugContain || this.globalConfig.hasSlugBV) {
          this.globalConfig.hasSlugBV = true;
          this.actionHasSlug();
        }
      }
    });
  }

  actionHasSlug() {
    this.el.nativeElement.style.display = 'none';
  }
}
