import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import en, { default as ngLang } from '@angular/common/locales/vi';
import { LOCALE_ID, NgModule, Type } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TransferHttpModule,
  TransferHttpService,
} from '@gorniv/ngx-transfer-http';
import { TransferHttpCacheModule } from '@nguniversal/common'; // HERE
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { NZ_I18N, vi_VN as zorroLang } from 'ng-zorro-antd/i18n';
import { JsonLdModule } from 'ngx-seo';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultInterceptor } from './core/net/default.interceptor';
import { WindowRef } from './core/utils/window-ref';
import { LayoutModule } from './layout/layout.module';
import { TransferRegisterService } from './routes/user/transfer-register.service';
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const LANG = {
  abbr: 'vi',
  ng: ngLang,
  zorro: zorroLang,
};
registerLocaleData(LANG.ng, LANG.abbr);
const LANG_PROVIDES = [
  { provide: LOCALE_ID, useValue: LANG.abbr },
  { provide: NZ_I18N, useValue: LANG.zorro },
];

const I18N_SERVICE_MODULES = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
];

const THIRDMODULES: Type<any>[] = [CommonModule, JsonLdModule];

registerLocaleData(en);

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    HttpClientModule,
    TransferHttpModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    ...THIRDMODULES,
    AppRoutingModule,
    LayoutModule,
    I18N_SERVICE_MODULES,
  ],
  declarations: [AppComponent],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    TransferHttpService,
    WindowRef,
    { provide: TransferRegisterService },
    ...LANG_PROVIDES,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
