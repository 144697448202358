import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Constant } from '../constant/constant';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  lang: string = 'vi';

  constructor(
    protected translate: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.setLanguage();
  }

  addLangs() {
    this.translate.addLangs(['en', 'vi']);
  }

  setDefaultLang() {
    this.translate.setDefaultLang('vi');
  }

  saveLang(lang: string) {
    localStorage.setItem(Constant.ls.lang, lang);
    this.setLanguage();
  }

  setLanguage(): string {
    let res = this.authenticationService.getLanguage();
    if (res) {
      this.lang = res!;
      this.translate.use(this.lang);
    } else {
      this.lang = 'vi';
      this.translate.use(this.lang);
    }
    return this.lang;
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.lang = language;
  }

  redirectAndShareLang() {
    let bookingUlr = environment.returnUrlBooking;
    let homeUlr = environment.returnUrlHome;
    let url: string = '';
    url = homeUlr;
    url += '?lang=' + this.lang;
    url += '&returnUrl=' + bookingUlr;

    window.location.href = url;
    return;
  }
}
