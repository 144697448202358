export const Constant = {
  pages: {
    login: {
      name: 'Đăng nhập',
      alias: 'login',
      hidden: true,
    },
    register: {
      name: 'Đăng ký',
      alias: 'register',
      hidden: true,
    },
    forgotPassword: {
      name: 'Quên mật khẩu',
      alias: 'forgot-password',
      hidden: true,
    },
    search: {
      name: 'Search',
      alias: 'search',
    },
    doctor: {
      name: 'Bác sĩ',
      alias: 'bac-si',
    },
    hospital: {
      name: 'Bệnh viện',
      alias: 'benh-vien',
    },
    clinic: {
      name: 'Phòng khám',
      alias: 'phong-kham',
    },
    record: {
      name: 'Hồ sơ',
      alias: 'ho-so',
    },
    appointment: {
      name: 'Lịch khám',
      alias: 'lich-kham',
    },
    account: {
      name: 'Tài khoản',
      alias: 'tai-khoan',
    },
    order: {
      name: 'Thanh-toan',
      alias: 'thanh-toan',
    },
    page404: {
      name: 'Không tìm thấy trang',
      alias: '404',
    },
    page403: {
      name: 'Không có quyền truy cập',
      alias: '403',
    },
    maintenancePage: {
      name: 'Bảo trì hệ thống',
      alias: 'bao-tri',
    },
  },
  ls: {
    auth: '_tk_ymb_bk',
    role: '_tk_ymb_rl',
    lang: '_tk_ymb_lang',
    userInfo: '_in_ymb_bk',
    serviceInfo: '_in_si_bk',
    orderType: 'pm_od_tp',
  },
};
